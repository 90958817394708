<template>
  <div v-if="dataSourceId !== ''">
    <div class="tw-m-3 tw-flex tw-flex-col tw-gap-4">
      <div v-if="showAppendDataSourceCheckbox" class="pb-2 tw-flex tw-flex-row tw-gap-4 tw-items-center">
        <!-- disabled for now - append new records in the previous upload -->
        <!-- <ui-checkbox v-model="isAppendDataUpload" label="Append to last upload"></ui-checkbox> -->
        <!-- <ui-tooltip position="right" text="This will add and merge CSV records with the previous uploaded records">
          <fa icon="fa-solid fa-circle-question" style="width: 15px; height: 15px" />
        </ui-tooltip> -->
      </div>
      <div class="tw-flex tw-flex-row tw-gap-4">
        <ui-form-label class="tw-w-24">Upload file:</ui-form-label>
        <input
          ref="fileInput"
          type="file"
          :class="`${disabled ? 'tw-opacity-50 tw-cursor-not-allowed' : ''}`"
          @change="filesChange($event.target.name, $event.target.files)"
          accept="text/csv"
          class="input-file tw-w-full"
        />
      </div>
    </div>
    <div class="tw-m-3">
      <ui-button :loading="isUploading" :disabled="uploadData === null" @click.native.stop="doUpload">Upload</ui-button>
      <div v-if="uploadStatus" class="tw-m-3 tw-text-gray-600 tw-font-sans">{{ uploadStatus }}</div>
      <v-progress-linear v-if="isUploading" :value="uploadProgress" color="accent" height="25"></v-progress-linear>
    </div>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';

import { upload } from '../../services/fileUploadService';
import UiButton from '../ui/UiButton.vue';
import UiCheckbox from '../ui/UiCheckbox.vue';
import UiFormLabel from '../ui/UiFormLabel.vue';
import UiTooltip from '../ui/UiTooltip.vue';

@Component({
  components: {
    UiButton,
    UiCheckbox,
    UiFormLabel,
    UiTooltip,
  },
})
export default class DataSourceUpload extends BaseVue {
  @Prop({ required: true })
  dataSourceId;

  @Prop({ default: false })
  disabled;

  @Prop({ default: false })
  showAppendDataSourceCheckbox;

  @Prop({ default: null })
  uploadSuccessCallback;

  uploadProgress = 0;
  isAppendDataUpload = false;
  uploadFieldName = 'csvFile';
  isUploading = false;
  canUpload = false;
  uploadData = null;
  importId = null;
  msg = '';
  uploadStatus = '';

  async doUpload() {
    this.isUploading = true;
    this.uploadStatus = 'Uploading';
    if (!this.dataSourceId) {
      return null;
    }
    try {
      const ds = DataApiManager.getInstance();
      const loadDetails = await ds.createDataSourceLoad(this.orgId, this.dataSourceId, { withCredentials: true });
      if (loadDetails.status === 200) {
        // do upload progress monitor
        const config = {
          onUploadProgress: (progressEvent) => {
            this.uploadProgress = Math.round((progressEvent.loaded / progressEvent.total) * 95);
            if (this.uploadProgress === 95) {
              this.uploadStatus = 'Wrapping up.';
            }
          },
        };

        // upload csv file to GCS
        const success = await upload(this.uploadData, loadDetails.data.uploadUrl, config);
        if (!success) throw new Error('Failed to upload document to the server!');

        const res = await ds.commitDataSourceLoad(
          this.orgId,
          this.dataSourceId,
          loadDetails.data.id,
          this.isAppendDataUpload,
          {
            withCredentials: true,
          }
        );
        console.log(`Commit data source load request finished with status ${res.status}`);
        this.uploadProgress = 100;
        if (res.status === 200) {
          this.showSuccessSnackbar(this.$tc('_dataLoadSuccess'));
          this.uploadSuccessCallback && this.uploadSuccessCallback();
        } else {
          this.showErrorSnackbar(
            this.$tc('_dataLoadFailure') + `- ${res.data.message !== '' ? ' - ' + res.data.message : ''}`
          );
          throw new Error(`Commit Data Source load failed ${res.data.message}`);
        }
      } else {
        this.showErrorSnackbar(this.$tc('_dataLoadFailure') + `${loadDetails.data.message}`);
        throw new Error(`Create Data Source load failed ${loadDetails.data.message}`);
      }
    } catch (err) {
      this.showErrorSnackbar(this.$tc('_dataLoadFailure') + `${err}`);
      console.error(`Error creating data source load - ${err}`);
    } finally {
      this.isUploading = false;
      this.uploadProgress = 0;
      this.uploadStatus = '';
    }
  }

  filesChange(fieldName, fileList) {
    if (fileList.length === 1) {
      this.uploadData = fileList[0];
    }
  }

  reset(msg) {
    this.showErrorSnackbar(msg);
    this.uploadData = null;
    this.isUploading = false;
    this.$refs.fileUpload = null;
  }
}
</script>
